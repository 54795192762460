import Image from "next/image";
import { type FlashcardSet, type Media, type Note } from "@knowt/syncing/graphql/schema";
import { timeDeltaFromNow } from "@knowt/syncing/utils/dateTimeUtils";
import { useClass } from "@knowt/syncing/hooks/classes/useClass";
import { ASSETS_URL } from "@/config/deployConstants";
import { Flex, FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import { themeColors } from "@/utils/themeColors";
import useSensitiveScrolling from "@/hooks/styles/useSensitiveScrolling";
import { ContentPill } from "./UserContentCardHandlerButtons";
import { CarouselDisplay, returnPerCarouselType } from "../utils";
import { isFlashcardSet } from "@knowt/syncing/utils/dataCleaning";
import { useMounted } from "@/hooks/useMounted";
import { isDarkColor } from "@knowt/syncing/utils/genericUtils";

type ItemType = Note | FlashcardSet | Media;

const PersonalPills = ({ item }: { item: ItemType }) => {
    const mounted = useMounted();

    const getStudiedCount = () => {
        return (
            <FlexRowAlignCenter>
                <Image
                    src={`${ASSETS_URL}/images/views-icon.svg`}
                    alt={"studied by"}
                    width={18}
                    height={18}
                    style={{ objectFit: "contain" }}
                />
                Studied by {item.views ?? 0} {(item.views ?? 0) === 1 ? "person" : "people"}
            </FlexRowAlignCenter>
        );
    };

    const getLastUpdated = () => {
        const time = timeDeltaFromNow(item?.updated ? +item?.updated * 1000 : 0);
        return mounted ? `Updated ${time} ago` : "Updated ... ago";
    };

    return (
        <>
            <ContentPill className="ellipsisText">{getStudiedCount()}</ContentPill>
            <ContentPill className="ellipsisText" style={{ maxWidth: "100%", display: "inline", lineHeight: "1rem" }}>
                {getLastUpdated()}
            </ContentPill>
        </>
    );
};

const TeacherClassPills = ({ item }: { item: Note | FlashcardSet | Media }) => {
    const sectionsDivRef = useSensitiveScrolling<HTMLDivElement>(30);

    const { course } = useClass({ classId: item.classId });

    const allSections = !item.sections;
    const noSections = !item.sections?.length;

    return (
        <>
            <div className="secondaryText2" style={{ marginBottom: "0.7rem", fontWeight: 600 }}>
                {"Shared with"}
            </div>
            {allSections || noSections ? (
                <ContentPill
                    style={{
                        backgroundColor: course?.color || themeColors.neutralBlack,
                        color: course?.color
                            ? isDarkColor(course.color)
                                ? themeColors.pureWhite
                                : themeColors.pureBlack
                            : themeColors.neutralWhite,
                        lineHeight: "1rem",
                    }}>
                    {allSections ? "All sections" : "No sections"}
                </ContentPill>
            ) : (
                <Flex
                    ref={sectionsDivRef}
                    className="scrollbar"
                    style={{ gap: "0.7rem", flexWrap: "wrap", maxHeight: "7.8rem", overflowY: "scroll" }}>
                    {item.sections?.map(sectionId => (
                        <ContentPill
                            key={sectionId}
                            className="ellipsisText"
                            style={{ width: "9rem", display: "inline", lineHeight: "1rem" }}>
                            {course?.sections.find(({ id }) => id === sectionId)?.name}
                        </ContentPill>
                    ))}
                </Flex>
            )}
        </>
    );
};

const StudentClassPills = ({ item }: { item: Note | FlashcardSet | Media }) => {
    const getPostedTimeDelta = () => {
        const time = timeDeltaFromNow(item?.addedAt ? +item?.addedAt * 1000 : 0);
        return `Posted ${time} ago`;
    };

    return (
        <ContentPill className="ellipsisText" style={{ maxWidth: "100%", display: "inline", lineHeight: "1rem" }}>
            {getPostedTimeDelta()}
        </ContentPill>
    );
};

const Pills = ({
    type,
    item,
    isTeacherClass = false,
    isStudentClass = false,
    props,
}: {
    type: CarouselDisplay;
    item: Note | FlashcardSet | Media;
    isTeacherClass?: boolean;
    isStudentClass?: boolean;
    props?: { numOfTerms: number | null };
}) => {
    const getContentType = () => {
        const mediaIcon = returnPerCarouselType(type, {
            note: <Image src={`${ASSETS_URL}/images/note-icon.svg`} alt="note" width="15" height="15" />,
            flashcardSet: (
                <Image src={`${ASSETS_URL}/images/flashcard-icon.svg`} alt="flashcards" width="15" height="15" />
            ),
            video: <Image src={`${ASSETS_URL}/images/video-icon.svg`} alt="video" width="15" height="15" />,
            pdf: <Image src={`${ASSETS_URL}/images/pdf-icon.svg`} alt="PDF" width="15" height="15" />,
            excel: <Image src={`${ASSETS_URL}/images/xlsx-icon.svg`} alt="xlsx" width="15" height="15" />,
            ppt: <Image src={`${ASSETS_URL}/images/pptx-icon.svg`} alt="pptx" width="15" height="15" />,
        });

        const mediaTypeName = returnPerCarouselType(type, {
            note: "Note",
            flashcardSet: "Flashcard",
            video: "Video",
            pdf: "PDF",
            excel: "Excel",
            ppt: "PowerPoint",
        });

        return (
            <FlexRowAlignCenter as="span" style={{ gap: "0.5rem" }}>
                {mediaIcon} {mediaTypeName}
            </FlexRowAlignCenter>
        );
    };

    const NumOfTerms = () => (
        <ContentPill style={!isStudentClass ? { backgroundColor: "var(--color-flashcardSet-pill)" } : {}}>
            {props?.numOfTerms} terms
        </ContentPill>
    );

    return (
        <FlexColumn
            className="bold"
            style={{
                flexWrap: "wrap",
                alignItems: "flex-start",
                height: "100%",
                gap: "0.6rem 1rem",
                padding: `1.2rem 0`,
                overflow: "hidden",
                flex: 1,
            }}>
            <FlexRowAlignCenter style={{ gap: "1rem", marginBottom: "1.5rem" }}>
                <ContentPill
                    style={{
                        backgroundColor: returnPerCarouselType(type, {
                            note: "var(--color-note-pill)",
                            flashcardSet: "var(--color-flashcardSet-pill)",
                            video: "var(--color-video-pill)",
                            pdf: "var(--color-pdf-pill)",
                            excel: "var(--color-excel-pill)",
                            ppt: "var(--color-ppt-pill)",
                        }),
                    }}>
                    {getContentType()}
                </ContentPill>
                {!isStudentClass && isFlashcardSet(item) && <NumOfTerms />}
            </FlexRowAlignCenter>
            {isStudentClass && isFlashcardSet(item) && <NumOfTerms />}
            {isTeacherClass ? (
                <TeacherClassPills item={item} />
            ) : isStudentClass ? (
                <StudentClassPills item={item} />
            ) : (
                <PersonalPills item={item} />
            )}
        </FlexColumn>
    );
};

export default Pills;
