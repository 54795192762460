"use client";

import {
    ExclusiveFlashcardSetItem,
    ExclusiveMediaItem,
    ExclusiveNoteItem,
    ExclusiveUserContent,
} from "@knowt/syncing/types/common";
import { type FlashcardSet, ItemType, type Media, type Note } from "@knowt/syncing/graphql/schema";
import { timeDeltaFromNow } from "@knowt/syncing/utils/dateTimeUtils";
import clsx from "clsx";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { useRouter } from "next13-progressbar";
import dynamic from "next/dynamic";
import { memo, MutableRefObject, useRef, useState } from "react";
import CardSkeleton from "../CardSkeleton";
import {
    BookmarkBtnWithHandler,
    CardRating,
    CarouselDots,
    OwnerDetails,
    PreviewButtonWithHandler,
    PublicIcon,
    SelectableButton,
    TitleWrapper,
} from "./components/UserContentCardHandlerButtons";
import styles from "./userContentCard.module.css";
import { CarouselDisplay, returnPerCarouselType, returnPerMediaType, returnPerNoteType, returnPerType } from "./utils";
import { themeColors } from "@/utils/themeColors";
import { iconSizes } from "@/utils/iconProps";
import { useCardPreviewContextSelector } from "@/contexts/CardPreviewContext";
import { FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import { getNoteUrl } from "@/utils/url";
import { isEqual } from "lodash";
import { isFlashcardSet, isMedia, isNote, UNTITLED } from "@knowt/syncing/utils/dataCleaning";
import CircularOutlineIcon from "@/components/CircularButton/styled/CircularOutlineIcon";
import Pills from "./components/Pills";

const NoteOptionsMenu = dynamic(() => import("./components/NoteOptionsMenu"), { ssr: false });
const FlashcardSetOptionsMenu = dynamic(() => import("./components/FlashcardSetOptionsMenu"), { ssr: false });
const MediaOptionsMenu = dynamic(() => import("./components/MediaOptionsMenu"), { ssr: false });

type ModeProps = {
    mode: "SELECT" | null;
    isSelected: boolean;
    toggleSelect: (itemId: string, item: Note | FlashcardSet | Media) => void;
};

type UserContentCardProps = {
    modeProps?: ModeProps;
    showOwnerDetails?: boolean;
    style?: React.CSSProperties;
    classProps?: {
        isTeacherClass?: boolean;
        isStudentClass?: boolean;
    };
} & (ExclusiveNoteItem | ExclusiveFlashcardSetItem | ExclusiveMediaItem);

const getCurrentDisplayCarousel = (item: Note | FlashcardSet | Media, carouselIndex: number): CarouselDisplay => {
    if (carouselIndex === 0) {
        return returnPerType(item, {
            note: returnPerNoteType(item as Note, {
                note: CarouselDisplay.NOTE,
                pdf: CarouselDisplay.PDF,
            }),
            flashcardSet: CarouselDisplay.FLASHCARDSET,
            media: returnPerMediaType(item as Media, {
                pdf: CarouselDisplay.PDF,
                video: CarouselDisplay.VIDEO,
                excel: CarouselDisplay.Excel,
                ppt: CarouselDisplay.PPT,
            }),
        }) as CarouselDisplay;
    }

    if (carouselIndex === 1) {
        if (isNote(item) || isMedia(item)) {
            const c1Display = returnPerType<CarouselDisplay | undefined>(item, {
                note: item.flashcardSetId
                    ? CarouselDisplay.FLASHCARDSET
                    : (item as Note).file
                    ? CarouselDisplay.PDF
                    : undefined,
                flashcardSet: undefined,
                media: item.noteId ? CarouselDisplay.NOTE : CarouselDisplay.FLASHCARDSET,
            });

            if (c1Display) return c1Display;
        }
    }

    if (carouselIndex === 2) {
        if (isNote(item) || isMedia(item)) {
            const c2Display = returnPerType<CarouselDisplay | undefined>(item, {
                note: !(item as Note).content && (item as Note).file ? CarouselDisplay.PDF : undefined,
                flashcardSet: undefined,
                media: item.flashcardSetId ? CarouselDisplay.FLASHCARDSET : undefined,
            });

            if (c2Display) return c2Display;
        }
    }

    throw new Error("Nothing returned from `getCurrentDisplayCarousel`");
};

const getMaxNumCarousel = (item: Note | FlashcardSet | Media) => {
    let max = 1;

    if (isNote(item)) {
        if (item.flashcardSetId) {
            max++;
        }
        if (item.content && item.file) {
            max++;
        }
    } else if (isFlashcardSet(item)) {
        // nothing so far, probably will have condition in future
    } else if (isMedia(item)) {
        if (item.noteId) {
            max++;
        }
        if (item.flashcardSetId) {
            max++;
        }
    }

    return max;
};

const UserContentCard = ({
    note,
    flashcardSet,
    media,
    modeProps,
    classProps,
    style,
    showOwnerDetails,
}: UserContentCardProps) => {
    const router = useRouter();
    const containerRef = useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>;

    const typeIsNote = !flashcardSet && !media;
    const typeIsFlashcardSet = !note && !media;

    const item: Note | FlashcardSet | Media = note || flashcardSet || media;

    const itemId = returnPerType(item, {
        note: (item as Note).noteId,
        flashcardSet: (item as FlashcardSet).flashcardSetId,
        media: (item as Media).mediaId,
    });

    const titlePrefix = returnPerType(item, {
        note: "",
        flashcardSet: (item as FlashcardSet).draft ? "(Draft) " : "",
        media: "",
    });

    const [carouselIdx, setCarouselIdx] = useState(0);

    const maxCarouselNum = getMaxNumCarousel(item);
    const showCarousel = maxCarouselNum > 1;
    const currDisplayCarousel = getCurrentDisplayCarousel(item, carouselIdx);

    const isCurrentlyInView = useCardPreviewContextSelector(state => state.isCurrentlyInView);
    const isPreviewOpen = useCardPreviewContextSelector(state => state.isOpen);

    const inView = isCurrentlyInView({ noteId: item.noteId, flashcardSetId: item.flashcardSetId }) && isPreviewOpen;

    const incrementCarousel = (by: number) => {
        setCarouselIdx(prev => Math.abs((prev + by) % maxCarouselNum));
    };

    const href = returnPerCarouselType(currDisplayCarousel, {
        note: getNoteUrl({
            noteId: (item as Note).noteId,
            title: (item as Note).title || UNTITLED,
        }),
        flashcardSet: `/flashcards/${item.flashcardSetId}`,
        video: `/media/${item.mediaId}`,
        pdf: `/media/${item.mediaId}`,
        excel: `/media/${item.mediaId}`,
        ppt: `/media/${item.mediaId}`,
    });

    const renderTopSection = () => {
        return (
            <FlexRowAlignCenter style={{ justifyContent: "space-between" }}>
                <FlexRowAlignCenter
                    as={modeProps?.mode === "SELECT" ? "div" : "a"}
                    className="strippedLink"
                    style={{ height: "2.6rem", width: "90%" }}
                    href={modeProps?.mode === "SELECT" ? undefined : href}>
                    {modeProps?.mode === "SELECT" ? (
                        <SelectableButton isSelected={modeProps.isSelected} />
                    ) : (
                        item.icon?.emoji && (
                            <FlexColumnAlignJustifyCenter
                                as="span"
                                style={{ width: "2.6rem", height: "2rem", fontSize: "1.5rem" }}>
                                {item.icon?.emoji}
                            </FlexColumnAlignJustifyCenter>
                        )
                    )}
                    <TitleWrapper title={titlePrefix + (item.title || UNTITLED)} />
                </FlexRowAlignCenter>
                {/* THREE DOTS - we must delay it since this is dynamic import, otherwise it will cause hydration error */}
                {returnPerType(item, {
                    note: <NoteOptionsMenu note={item as Note} parentRef={containerRef} />,
                    flashcardSet: (
                        <FlashcardSetOptionsMenu flashcardSet={item as FlashcardSet} parentRef={containerRef} />
                    ),
                    media: <MediaOptionsMenu media={item as Media} parentRef={containerRef} />,
                })}
            </FlexRowAlignCenter>
        );
    };

    const renderMiddleSection = () => {
        return (
            <FlexRowAlignCenter style={{ height: "19rem", gap: "1.7rem" }}>
                {showCarousel && (
                    <CircularOutlineIcon
                        Icon={ChevronLeft}
                        size={iconSizes.SM_S}
                        color={themeColors.neutralBlack}
                        buttonColor={themeColors.background}
                        onClick={() => incrementCarousel(-1)}
                        tooltip={`go to linked resources`}
                        radius="2.3rem"
                    />
                )}

                <Pills
                    item={item}
                    props={{ numOfTerms: flashcardSet?.flashcards?.length || flashcardSet?.size || 0 }}
                    type={currDisplayCarousel}
                    isTeacherClass={classProps?.isTeacherClass}
                    isStudentClass={classProps?.isStudentClass}
                />

                {showCarousel && (
                    <CircularOutlineIcon
                        Icon={ChevronRight}
                        size={iconSizes.SM_S}
                        color={themeColors.neutralBlack}
                        buttonColor={themeColors.background}
                        onClick={() => incrementCarousel(1)}
                        tooltip={`go to linked resources`}
                        radius="2.3rem"
                    />
                )}
            </FlexRowAlignCenter>
        );
    };

    const renderBottomSection = () => {
        const isPublic = item.public;

        const getLastUpdated = () => {
            if (!item.updated) return "Updated ... ago";
            const time = timeDeltaFromNow(item.updated ? +item.updated * 1000 : 0);
            return `Updated ${time} ago`;
        };

        const renderPersonalPills = () => (
            <>
                {showOwnerDetails ? (
                    <OwnerDetails userId={item.userId} />
                ) : (
                    <PublicIcon item={{ media, note, flashcardSet } as ExclusiveUserContent} isPublic={!!isPublic} />
                )}
                <CardRating rating={item.rating} ratingCount={item.ratingCount} />
                <div style={{ marginLeft: "auto" }} />
            </>
        );

        const renderClassPills = () => (
            <span style={{ fontSize: "1.2rem", fontWeight: "600", color: themeColors.neutralBlack }}>
                {getLastUpdated()}
            </span>
        );

        return (
            <FlexRowAlignCenter style={{ gap: "0.5rem", justifyContent: "space-between" }}>
                {classProps?.isTeacherClass || classProps?.isStudentClass ? renderClassPills() : renderPersonalPills()}

                <FlexRowAlignCenter
                    style={{
                        gap: "10px",
                    }}>
                    {((typeIsNote && !note?.file) || typeIsFlashcardSet) && (
                        <PreviewButtonWithHandler
                            {...(() => {
                                if (note && currDisplayCarousel === CarouselDisplay.NOTE) {
                                    return { noteId: note.noteId };
                                } else if (currDisplayCarousel === CarouselDisplay.FLASHCARDSET) {
                                    return { flashcardSetId: item.flashcardSetId as string };
                                }
                            })()}
                        />
                    )}
                    <BookmarkBtnWithHandler
                        item={item}
                        itemId={itemId}
                        type={returnPerType(item, {
                            note: ItemType.NOTE,
                            flashcardSet: ItemType.FLASHCARDSET,
                            media: ItemType.MEDIA,
                        })}
                    />
                </FlexRowAlignCenter>
            </FlexRowAlignCenter>
        );
    };

    const maybeCarouselDots = () => {
        if (!showCarousel) {
            return null;
        }

        return <CarouselDots length={maxCarouselNum} currIdx={carouselIdx} onClick={i => setCarouselIdx(i)} />;
    };

    return (
        <CardSkeleton
            role="link"
            data-testid={"notebook-card"}
            data-href={href}
            tabIndex={0}
            className={clsx({
                [styles.userContentCard]: true,
                [styles.carouselColorsInit]: true,
                [styles.note]: currDisplayCarousel === CarouselDisplay.NOTE,
                [styles.flashcardSet]: currDisplayCarousel === CarouselDisplay.FLASHCARDSET,
                [styles.pdf]: currDisplayCarousel === CarouselDisplay.PDF,
                [styles.video]: currDisplayCarousel === CarouselDisplay.VIDEO,
                [styles.ppt]: currDisplayCarousel === CarouselDisplay.PPT,
                [styles.excel]: currDisplayCarousel === CarouselDisplay.Excel,
                [styles.inView]: inView,
            })}
            onClick={
                modeProps?.mode === "SELECT"
                    ? () => modeProps?.toggleSelect(itemId, item)
                    : e => {
                          if (e.metaKey || e.ctrlKey) {
                              return window.open(href, "_blank");
                          }
                          router.push(href);
                      }
            }
            ref={containerRef}
            renderTopSection={renderTopSection}
            renderMiddleSection={renderMiddleSection}
            renderBottomSection={() => {
                return (
                    <>
                        {renderBottomSection()} {maybeCarouselDots()}
                    </>
                );
            }}
            style={{
                width: "100%",
                cursor: "pointer",
                justifyContent: "space-between",
                position: "relative",
                backgroundColor: undefined,
                ...style,
            }}
        />
    );
};

export default memo(UserContentCard, (prevProps, nextProps) => isEqual(prevProps, nextProps));
