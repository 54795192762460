import { useSWRImmutable } from "@knowt/syncing/hooks/swr";
import { fetchUsersInfo } from "@/hooks/user/graphqlUtils";
import { UserDetails } from "@knowt/syncing/graphql/schema";
import { populateCacheWithFallbackData } from "@/hooks/swr";

export const usePublicProfile = (userId?: string, fallbackData = undefined) => {
    const { data: userData, mutate } = useSWRImmutable(
        userId && ["publicUserData", userId],
        () => fetchUsersInfo({ userIds: [userId] }).then(r => r[userId] ?? null),
        {
            fallbackData,
            use: [populateCacheWithFallbackData],
        }
    );

    return { userData: userData as UserDetails, mutate };
};
