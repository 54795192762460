// eslint-disable-next-line no-restricted-imports
import { SxProps } from "@mui/material";
import CircularRectTextButton from "./CircularRectTextButton";
import { themeColors } from "@/utils/themeColors";
import { forwardRef } from "react";

export const OutlineTextButton = forwardRef(
    (
        {
            text,
            onClick,
            onMouseEnter,
            tooltip,
            sx,
            className,
            id,
        }: {
            id?: string;
            borderColor?: string;
            text: React.ReactNode;
            onClick?: (e) => void;
            onMouseEnter?: (e) => void;
            tooltip?: string;
            sx?: SxProps;
            className?: string;
        },
        ref
    ) => {
        return (
            <CircularRectTextButton
                ref={ref}
                id={id}
                className={className}
                component={onClick ? "button" : "div"}
                sx={{
                    border: `1px solid ${themeColors.neutralBlack}`,
                    backgroundColor: "transparent",
                    color: themeColors.neutralBlack,
                    fontSize: "1.1rem",
                    width: "auto",
                    padding: "0.5rem 1.5rem",
                    height: "3.8rem",
                    "&:hover": {
                        backgroundColor: themeColors.neutralBlack,
                        color: themeColors.neutralWhite,
                    },
                    "&:active": {
                        transform: "scale(0.97)",
                    },
                    transition: "all 0.2s ease",
                    ...sx,
                }}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                tooltip={tooltip}>
                {text}
            </CircularRectTextButton>
        );
    }
);

OutlineTextButton.displayName = "OutlineTextButton";
export default OutlineTextButton;
