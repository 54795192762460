import { ChevronRight, LucideProps } from "lucide-react";

/** Lucide `ChevronRight` icon. `size` will be extended to `minWidth` and `minHeight` to prevent svg shrinking */
export const SlugSeparatorChevron = ({ size = 24, style, ...svgProps }: LucideProps) => {
    return (
        <ChevronRight
            size={size}
            style={{ margin: "auto 0.8rem", minWidth: size, minHeight: size, ...style }}
            {...svgProps}
        />
    );
};

export const SlugSeparatorSlice = ({ style, ...spanProps }: React.HTMLAttributes<HTMLSpanElement>) => {
    return (
        <span style={{ margin: "auto 0.8rem", ...style }} {...spanProps}>
            /
        </span>
    );
};
