import { useSWRImmutable } from "@knowt/syncing/hooks/swr";
import { useCallback, useMemo } from "react";
import { mutate } from "swr";
import { useCurrentUser } from "../user/useCurrentUser";
import { fetchBookmarks, updateBookmarking } from "./utils";
import { platform } from "@/platform";
import { ItemType } from "@knowt/syncing/graphql/schema";
import { resolveClassSWRKey, updateClass } from "../classes/utils";
import { toggleArrayElements } from "@/utils/arrayUtils";
import { Bookmark } from "@/graphql/schema";
import { callGetClass } from "@/hooks/classes/graphqlUtils";

export type BookmarkProps = {
    itemId: string;
    type: ItemType;
    classId: string | null | undefined;
    itemUserId: string | null | undefined;
};

export const useAllBookmarks = () => {
    const { userId } = useCurrentUser();

    const { data: bookmarksSWR, mutate: mutateBookmarks } = useSWRImmutable(
        userId && ["bookmarks", userId],
        ([_, userId]) => fetchBookmarks({ userId })
    );

    const toggleBookmark = useCallback(
        async ({ itemId, type, classId, itemUserId }: BookmarkProps) => {
            if (!Array.isArray(bookmarksSWR)) {
                throw new Error("bookmarksSWR is not an array");
            }

            if (!itemId) {
                const { log } = await platform.analytics.logging();
                log(`itemId is not provided. Found: ${itemId}`);
                throw new Error(`itemId is not provided. Found: ${itemId}`);
            }

            if (!type) {
                throw new Error(`type is not provided. Found: ${type}`);
            }

            const shouldBookmark = bookmarksSWR.find(({ ID }) => ID === itemId) === undefined;

            await mutateBookmarks(
                oldBookmarks =>
                    toggleArrayElements(
                        oldBookmarks,
                        [{ ID: itemId, type, userId } as undefined as Bookmark],
                        (a, b) => a.ID === b.ID
                    ),
                { revalidate: false }
            );

            const isPin = itemUserId === userId;

            const showSuccessToast = async (shouldBookmark: boolean) => {
                const toast = await platform.toast();
                if (shouldBookmark) {
                    toast.success(
                        isPin
                            ? "Pinned!" + classId
                                ? " This will also be pinned for your students."
                                : ""
                            : `Added to saved!`
                    );
                } else {
                    toast.success(isPin ? "Unpinned." : `Removed from saved.`);
                }
            };

            const showFailureToast = async (shouldBookmark: boolean) => {
                const toast = await platform.toast();
                if (shouldBookmark) {
                    toast.error(`Failed to add to saved, please try again later.`);
                } else {
                    toast.error(`Failed to remove from saved, please try again later.`);
                }
            };

            updateBookmarking(shouldBookmark, itemId, type)
                // TODO: show different message based on if owner or not (pinned vs saved)
                .then(() => showSuccessToast(shouldBookmark))
                .catch(() => {
                    showFailureToast(shouldBookmark);
                    mutateBookmarks();
                });

            if (classId) {
                const currentCachedCourse = await mutate(resolveClassSWRKey({ userId, classId }), current => current, {
                    revalidate: false,
                });

                const currentCourse = currentCachedCourse ?? (await callGetClass({ classId }));

                const updatedClass = {
                    ...currentCourse,
                    pinned: toggleArrayElements(currentCourse.pinned, [itemId]),
                };

                await mutate(resolveClassSWRKey({ userId, classId }), updatedClass, { revalidate: false });

                await updateClass(updatedClass, { ID: userId });
            }
        },
        [bookmarksSWR, mutateBookmarks, userId]
    );

    const bookmarksMap = useMemo(() => {
        if (!Array.isArray(bookmarksSWR)) {
            return {};
        }

        return bookmarksSWR.reduce((map, { ID }) => ({ ...map, [ID]: true }), {} as Record<string, boolean>);
    }, [bookmarksSWR]);

    return {
        bookmarks: bookmarksMap,
        rawBookmarks: bookmarksSWR,
        toggleBookmark,
    };
};

export const useBookmark = ({ itemId, type, classId, itemUserId }: BookmarkProps) => {
    const { bookmarks, toggleBookmark } = useAllBookmarks();

    const bookmarked = !!bookmarks[itemId];

    const toggleBookmarked = useCallback(async () => {
        await toggleBookmark({ itemId, type, classId, itemUserId });
    }, [toggleBookmark, itemId, type, classId, itemUserId]);

    return { bookmarked, toggleBookmarked };
};
