import { SubscriptionType, UserDetails } from "@knowt/syncing/graphql/schema";
import Image from "next/image";
import React from "react";
import { FlexRowAlignCenter } from "./Flex";
import { themeColors } from "@/utils/themeColors";
import { ASSETS_URL } from "@/config/deployConstants";

const verified = `${ASSETS_URL}/images/verified_badge.png`;
const robotImg = `${ASSETS_URL}/images/robot.png`;
const supporterImg = `${ASSETS_URL}/images/supporter_badge.png`;

type UserNameWithBadgeProps = {
    user: Partial<UserDetails>;
    priority?: "supporter" | "verified" | "both";
    type: "Name" | "username";
    badgeSize?: number;
} & React.HTMLAttributes<HTMLSpanElement>;

const BADGES = {
    [SubscriptionType.SUPPORTER]: supporterImg,
    [SubscriptionType.PRO]: robotImg,
    [SubscriptionType.LIMITLESS]: `${ASSETS_URL}/images/diamond.png`,
};

const UserNameWithBadge = ({ user, type, priority = "both", badgeSize = 20, ...props }: UserNameWithBadgeProps) => {
    const hasBadge = user?.subscriptionType && user?.subscriptionType !== SubscriptionType.BASIC;

    const showVerifiedBadge = user?.verified && !(hasBadge && priority === "supporter");
    const showBadge = hasBadge && !(user?.verified && priority === "verified");
    const showInvitesBadge = (user.invites || 0) >= 5;

    return (
        <FlexRowAlignCenter style={{ columnGap: "0.5rem" }}>
            <span style={{ fontSize: "1.7rem", color: themeColors.neutralBlack }} {...props}>
                {user?.[type]}
            </span>
            {showVerifiedBadge && <Image src={verified} alt="verify" height={badgeSize} width={badgeSize} />}
            {showBadge && (
                <Image src={BADGES[user?.subscriptionType]} alt="support" height={badgeSize} width={badgeSize} />
            )}
            {showInvitesBadge && (
                <Image src={"/images/invites-badge.svg"} alt="invites" height={badgeSize} width={badgeSize} />
            )}
        </FlexRowAlignCenter>
    );
};

export default UserNameWithBadge;
