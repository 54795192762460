"use client";

import Image from "next/image";
import React from "react";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";
import { SlugPillFilled, SlugSeparatorChevron } from "@/features/MoveToPopup";
import { iconSizes } from "@/utils/iconProps";
import LinkWrapper from "@/components/wrappers/Link";
import { capitalize } from "@knowt/syncing/utils/stringUtils";
import { FlexRowAlignCenter } from "./Flex";
import br from "@/styles/breakpoints.module.css";

type BreadcrumbProps = {
    titleSections: TitleSections[];
    style?: React.CSSProperties;
    root?: "home" | "explore" | "exams" | "blog";
};

export type TitleSections = {
    title: React.ReactNode;
    image?: string;
    alt?: string;
    href?: string;
};

const Breadcrumb = ({ titleSections, style, root = "home" }: BreadcrumbProps) => {
    const { mdDown } = useBreakPoints();

    return (
        <FlexRowAlignCenter
            className={br.smDownFullWidthScrollableX}
            style={{
                fontSize: mdDown ? "2rem" : "1.4rem",
                gap: mdDown ? "0.8rem" : "0.3rem",
                overflowX: "auto",
                scrollbarWidth: "none",
                msOverflowStyle: "none",
                ...style,
            }}>
            <LinkWrapper
                href={root === "explore" ? "/explore" : root === "exams" ? "/exams" : root === "blog" ? "/blog" : "/"}>
                <SlugPillFilled component="span">
                    <Image src={`/images/${root}-icon.svg`} width={iconSizes.SM} height={iconSizes.SM} alt={root} />
                    {capitalize(root)}
                </SlugPillFilled>
            </LinkWrapper>
            {titleSections.map((section, i) => {
                if (!section) return;
                return (
                    <React.Fragment key={String(section.href) + i}>
                        <SlugSeparatorChevron />
                        <LinkWrapper href={section.href}>
                            <SlugPillFilled component="span">
                                {section.image && (
                                    <Image
                                        src={section.image}
                                        width={iconSizes.SM}
                                        height={iconSizes.SM}
                                        alt={typeof section.title === "string" ? section.title : "knowt breadcrumb"}
                                    />
                                )}
                                {section.title}
                            </SlugPillFilled>
                        </LinkWrapper>
                    </React.Fragment>
                );
            })}
        </FlexRowAlignCenter>
    );
};

export default Breadcrumb;
