import { ExclusiveUserContent } from "@knowt/syncing/types/common";
import { useBookmark } from "@knowt/syncing/hooks/bookmarks/useBookmarks";
import { usePublicProfile } from "@knowt/syncing/hooks/user/useUserData";
import { FlashcardSet, ItemType, Media, Note } from "@knowt/syncing/graphql/schema";
import { isMedia, UNTITLED } from "@knowt/syncing/utils/dataCleaning";
import ButtonBase from "@mui/material/ButtonBase";
import { Bookmark, Check, Circle, Lock, LucideProps, MoreHorizontal, MoreVertical, Pin, Unlock } from "lucide-react";
import React, { forwardRef, useRef } from "react";
import { FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import StarRating from "@/components/StarRating";
import CircularButton, { CircularButtonProps } from "@/components/CircularButton";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import UserAvatar from "@/components/userCardComponents/UserAvatar";
import ConditionalTooltip from "@/components/wrappers/ConditionalTooltip";
import { useCardPreviewContextSelector } from "@/contexts/CardPreviewContext";
import { useCheckAuth } from "@/hooks/useCheckAuth";
import useDOMEventListener from "@/hooks/useDOMEventListener";
import { preventStopPropogation } from "@/utils/domUtils";
import { iconSizes, strokeWidth } from "@/utils/iconProps";
import { themeColors } from "@/utils/themeColors";
import toast from "react-hot-toast";
import { AuthEvent } from "@/features/Auth";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { useSharingPopupContextSelector } from "@/features/ShareSettings";

export const TitleWrapper = ({ title: rawTitle }: { title: string }) => {
    const title = rawTitle.trim() || UNTITLED;
    return (
        <div title={title} className="bold ellipsisText" style={{ fontSize: "1.3rem" }}>
            {title}
        </div>
    );
};

export const PreviewButtonWithHandler = ({ noteId, flashcardSetId }: { noteId?: string; flashcardSetId?: string }) => {
    const openCardPreview = useCardPreviewContextSelector(state => state.openCardPreview);
    const isCurrentlyInView = useCardPreviewContextSelector(state => state.isCurrentlyInView);

    const inView = isCurrentlyInView({ noteId, flashcardSetId });

    return (
        <div onClick={preventStopPropogation}>
            <CircularRectTextButton
                component={ButtonBase}
                onClick={() => {
                    if (!noteId && !flashcardSetId) {
                        return alert("TODO: coming for this, currently only for preview note and flashcards.");
                    }

                    if (noteId) {
                        return openCardPreview({ noteId });
                    } else if (flashcardSetId) {
                        return openCardPreview({ flashcardSetId });
                    }

                    throw new Error(
                        "failed to call `openCardPreview`, make sure it's only for `noteId` or `flashcardSetId`"
                    );
                }}
                sx={{
                    border: `1px solid ${themeColors.neutralBlack}`,
                    fontSize: "1rem",
                    height: "2.6rem",
                    width: "6.4rem",
                    backgroundColor: inView ? "#000000" : "transparent",
                    color: inView ? themeColors.pureWhite : undefined,
                    "&:hover": { backgroundColor: "rgba(29, 29, 29, 0.15)" },
                }}>
                preview
            </CircularRectTextButton>
        </div>
    );
};

export const BookmarkBtnWithHandler = ({
    item,
    itemId,
    type,
}: {
    item: Note | FlashcardSet | Media;
    itemId: string;
    type: ItemType;
}) => {
    const { bookmarked, toggleBookmarked } = useBookmark({
        itemId: itemId,
        type: type,
        classId: item.classId,
        itemUserId: item.userId,
    });

    const { userId } = useCurrentUser();
    const { checkAuth } = useCheckAuth();

    const isPin = item.userId === userId;

    const SaveIcon = isPin ? Pin : Bookmark;

    return (
        <CircularButton
            component={ButtonBase}
            onClick={e => {
                preventStopPropogation(e);
                checkAuth(() => toggleBookmarked(), {
                    event: AuthEvent.BOOKMARK_ITEM,
                });
            }}
            tooltip={bookmarked ? (isPin ? "unpin this" : "remove from saved") : isPin ? "pin this" : "add to saved"}
            radius="2.6rem"
            sx={{
                backgroundColor: bookmarked ? themeColors.primary3 : "transparent",
                border: `1px solid`,
                borderColor: bookmarked ? themeColors.primary3 : themeColors.neutralBlack,
                ...(!bookmarked ? { "&:hover": { backgroundColor: "rgba(29, 29, 29, 0.15)" } } : {}),
            }}>
            <SaveIcon
                aria-label={isPin ? "pin" : "save"}
                size={iconSizes.XS}
                color={bookmarked ? themeColors.pureBlack : themeColors.neutralBlack}
                fill={bookmarked ? themeColors.pureBlack : themeColors.neutralBlack}
                strokeWidth={strokeWidth.normal}
            />
        </CircularButton>
    );
};

export const PublicIcon = ({ isPublic, item }: { isPublic: boolean; item: ExclusiveUserContent }) => {
    const openSharingPopup = useSharingPopupContextSelector(state => state.openSharingPopup);

    return (
        <CircularButton
            onClick={e => {
                e.stopPropagation();
                if (!item) {
                    return;
                }
                if (isMedia(item)) {
                    return toast.error("coming soon!");
                }

                openSharingPopup({ ...item });
            }}
            radius="2.6rem"
            aria-label={"share"}
            sx={{ backgroundColor: isPublic ? "#416D97" : "#000000" }}>
            {isPublic ? (
                <Unlock size={iconSizes.SM_S} strokeWidth={strokeWidth.normal} color={themeColors.pureWhite} />
            ) : (
                <Lock size={iconSizes.SM_S} strokeWidth={strokeWidth.normal} color={themeColors.pureWhite} />
            )}
        </CircularButton>
    );
};

export const CardRating = ({ rating, ratingCount }: { rating?: number | null; ratingCount?: number | null }) => {
    return (
        <FlexRowAlignCenter style={{ gap: "0.5rem", fontSize: "1.1rem", overflow: "hidden" }}>
            <span className="bold" style={{ width: "fit-content", height: "1.2rem" }}>
                {rating?.toFixed(1) || "0.0"} Stars
            </span>
            <StarRating readOnly max={1} value={rating || 0} sx={{ fontSize: "1.4rem" }} />
            <span style={{ color: themeColors.neutral3 }}>({ratingCount || 0})</span>
        </FlexRowAlignCenter>
    );
};

export const SelectableButton = ({ isSelected }: { isSelected: boolean }) => {
    return (
        <CircularButton
            radius="2.1rem"
            data-testid="notebook-select-btn"
            aria-label={"select mode toggler"}
            sx={{
                border: !isSelected ? "2px solid #dddddd" : "0",
                backgroundColor: !isSelected ? "transparent" : themeColors.primary,
                marginRight: "0.5rem",
            }}>
            {isSelected ? (
                <Check size={iconSizes.LG} strokeWidth={strokeWidth.bold} color={themeColors.primaryDark} />
            ) : (
                " "
            )}
        </CircularButton>
    );
};

export const OwnerDetails = ({ userId: ownerUserId }) => {
    const { userData: ownerData } = usePublicProfile(ownerUserId);

    return (
        <span title={ownerData?.username}>
            <UserAvatar
                alt={ownerData?.username}
                radius="2.6rem"
                src={ownerData?.pictureUrl}
                sx={{ backgroundColor: "#416D97" }}
            />
        </span>
    );
};

export const ContentPill = ({
    children,
    style,
    ...spanProps
}: {
    children: React.ReactNode;
    style?: React.CSSProperties;
} & React.HTMLAttributes<HTMLSpanElement>) => {
    return (
        <FlexColumnAlignJustifyCenter
            as={"span"}
            style={{
                borderRadius: 10,
                width: "auto",
                height: "3.5rem",
                padding: "1.4rem",
                fontSize: "1.2rem",
                flexShrink: 0,
                backgroundColor: "var(--color-current-pill)",
                transition: "background-color 0.14s",
                ...style,
            }}
            {...spanProps}>
            {children}
        </FlexColumnAlignJustifyCenter>
    );
};

export type ThreeDotsBtnProps = CircularButtonProps & {
    color?: string;
    cardContainerRef: React.MutableRefObject<HTMLDivElement> | null;
    onRightClick: (e: React.MouseEvent<Element, MouseEvent>) => void;
    horizontalImage?: boolean;
    iconProps?: LucideProps;
};

export const ThreeDotsBtn = forwardRef<unknown, ThreeDotsBtnProps>(
    ({ onClick, sx, color, onRightClick, cardContainerRef, horizontalImage = false, iconProps, ...props }, btnRef) => {
        const ref = useRef<HTMLSpanElement>(null);

        // handle right click
        useDOMEventListener(
            "contextmenu",
            (e: React.MouseEvent) => {
                if (!ref.current) throw new Error("ThreeDotsBtn: `ref` not being set");
                if (!cardContainerRef) return;

                preventStopPropogation(e);
                // bind this button to event
                e = { ...e, currentTarget: ref.current };

                onRightClick(e);
            },
            cardContainerRef?.current
        );

        return (
            <span ref={ref}>
                <CircularButton
                    ref={btnRef}
                    component={ButtonBase}
                    onClick={e => {
                        preventStopPropogation(e);
                        onClick?.(e);
                    }}
                    sx={{
                        backgroundColor: "transparent",
                        "&:hover": { boxShadow: "inset 0 0 0 10em rgba(200, 200, 200, 0.35)" },
                        ...sx,
                    }}
                    tooltip="more actions"
                    radius={"26px"}
                    {...props}>
                    <FlexColumnAlignJustifyCenter as="span">
                        {horizontalImage ? (
                            <MoreHorizontal
                                size={iconSizes.SM + 1}
                                strokeWidth={strokeWidth.normal}
                                color={color ?? themeColors.neutralBlack}
                                fill={themeColors.neutralBlack}
                                {...iconProps}
                            />
                        ) : (
                            <MoreVertical
                                size={iconSizes.SM + 1}
                                strokeWidth={strokeWidth.normal}
                                color={color ?? themeColors.neutralBlack}
                                fill={themeColors.neutralBlack}
                                {...iconProps}
                            />
                        )}
                    </FlexColumnAlignJustifyCenter>
                </CircularButton>
            </span>
        );
    }
);

ThreeDotsBtn.displayName = "ThreeDotsBtn";

export const CarouselDots = ({
    length,
    currIdx,
    onClick,
}: {
    length: number;
    onClick: (clickedIdx: number) => void;
    currIdx: number;
}) => {
    return (
        <FlexRowAlignCenter
            onClick={preventStopPropogation}
            style={{
                gap: "0.7rem",
                position: "absolute",
                bottom: "calc(-1.4rem - 0.8rem)",
                left: "50%",
                transform: "translateX(-50%)",
                cursor: "default",
            }}>
            {new Array(length).fill(0).map((_, i) => {
                return (
                    <ConditionalTooltip key={i} tooltip={currIdx === i ? undefined : "jump between linked files"}>
                        <Circle
                            size={8}
                            color={currIdx === i ? themeColors.neutralBlack : "#bfbfbf"}
                            fill={currIdx === i ? "#000000" : "#bfbfbf"}
                            style={{ cursor: currIdx === i ? "default" : "pointer" }}
                            onClick={() => onClick(i)}
                        />
                    </ConditionalTooltip>
                );
            })}
        </FlexRowAlignCenter>
    );
};
