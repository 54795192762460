import { FlashcardSet, Media, Note } from "@knowt/syncing/graphql/schema";
import { getNoteUrl } from "@/utils/url";
import { isPDF, isExcel, isPPT, isVideo } from "@knowt/syncing/hooks/media/checkers";
import { isFlashcardSet, isMedia, isNote } from "@knowt/syncing/utils/dataCleaning";

export enum CarouselDisplay {
    NOTE = "NOTE",
    FLASHCARDSET = "FLASHCARDSET",
    VIDEO = "Video",
    PDF = "PDF",
    AUDIO = "AUDIO",
    Excel = "Excel",
    PPT = "PPT",
}

export const getItemUrl = ({
    note,
    flashcardSet,
    media,
}: {
    note?: Note;
    flashcardSet?: FlashcardSet;
    media?: Media;
}): string => {
    if (note) {
        const { noteId, title } = note;
        return getNoteUrl({ noteId, ...(title ? { title } : {}) });
    } else if (flashcardSet) {
        const { draft, flashcardSetId } = flashcardSet;
        return draft ? `/flashcards/${flashcardSetId}/edit` : `/flashcards/${flashcardSetId}`;
    } else if (media) {
        return `/media/${media?.mediaId}`;
    }

    throw new Error("`getItemUrl` should be call with note or flashcardSet or media");
};

export const returnPerCarouselType = <T,>(
    type: CarouselDisplay,
    returns: { note: T; flashcardSet: T; video: T; pdf: T; excel: T; ppt: T }
) => {
    switch (type) {
        case CarouselDisplay.NOTE:
            return returns.note;
        case CarouselDisplay.FLASHCARDSET:
            return returns.flashcardSet;
        case CarouselDisplay.VIDEO:
            return returns.video;
        case CarouselDisplay.PDF:
            return returns.pdf;
        case CarouselDisplay.Excel:
            return returns.excel;
        case CarouselDisplay.PPT:
            return returns.ppt;
        default:
            throw new Error("`returnPerCarouselType` should be call with note or flashcardSet or media");
    }
};

export const returnPerType = <T,>(
    item: Note | FlashcardSet | Media,
    returns: { note: T; flashcardSet: T; media: T }
) => {
    if (isNote(item)) {
        return returns.note;
    }
    if (isFlashcardSet(item)) {
        return returns.flashcardSet;
    }
    if (isMedia(item)) {
        return returns.media;
    }

    // eslint-disable-next-line no-console
    console.log("ITEM", item);
    throw new Error("`returnPerType` should be call with note or flashcardSet or media");
};

export const returnPerNoteType = <T,>(item: Note, returns: { note: T; pdf: T }) => {
    if (item.file && !item.content) {
        return returns.pdf;
    }
    return returns.note;
};

export const returnPerMediaType = <T,>(item: Media, returns: { pdf: T; excel: T; ppt: T; video: T }) => {
    if (isPDF(item)) return returns.pdf;
    if (isExcel(item)) return returns.excel;
    if (isPPT(item)) return returns.ppt;
    if (isVideo(item)) return returns.video;

    return returns.video;
    // throw new Error("`returnPerMediaType` should be call with note or flashcardSet or media");
};
