import { useRef } from "react";
import useDOMEventListener from "../useDOMEventListener";

/**
 * @param distance scroll distance
 */
// code reference https://stackoverflow.com/questions/7408100/can-i-change-the-scroll-speed-using-css-or-jquery

const useSensitiveScrolling = <T extends HTMLElement>(distance = 100) => {
    const divRef = useRef<T>(null);

    const handleWheel = event => {
        let delta = 0;
        if (event.wheelDelta) {
            delta = event.wheelDelta / 120;
        } else if (event.detail) {
            delta = -event.detail / 3;
        }

        handle(delta);
        if (event.preventDefault) {
            event.preventDefault();
        }
        event.returnValue = false;
    };

    const handle = delta => {
        const targetDiv = divRef.current;

        if (targetDiv) {
            targetDiv.scrollTop = targetDiv.scrollTop - distance * delta;
        }
    };

    useDOMEventListener("wheel", handleWheel, divRef?.current);

    return divRef;
};

export default useSensitiveScrolling;
