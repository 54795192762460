import React, { forwardRef } from "react";
import { FlexColumn } from "@/components/Flex";
import { themeColors } from "@/utils/themeColors";

export type CardSkeletonProps = {
    style: React.CSSProperties;
    renderTopSection: () => React.ReactNode;
    renderMiddleSection: () => React.ReactNode;
    renderBottomSection: () => React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

const CardSkeleton = forwardRef<HTMLDivElement, CardSkeletonProps>(
    ({ style, renderTopSection, renderMiddleSection, renderBottomSection, ...domProps }, ref) => {
        return (
            <FlexColumn
                ref={ref}
                style={{
                    position: "relative",
                    color: themeColors.neutralBlack,
                    justifyContent: "center",
                    backgroundColor: themeColors.card,
                    borderRadius: "2rem",
                    padding: "1.8rem 2rem",
                    gap: "0",
                    ...style,
                }}
                {...domProps}>
                {renderTopSection()}
                {renderMiddleSection()}
                {renderBottomSection()}
            </FlexColumn>
        );
    }
);

CardSkeleton.displayName = "CardSkeleton";
export default CardSkeleton;
