import CircularRectTextButton, {
    CircularRectTextButtonProps,
} from "@/components/CircularButton/styled/CircularRectTextButton";
import { themeColors } from "@/utils/themeColors";
import { forwardRef } from "react";

export const SlugPillFilled = forwardRef(({ children, sx, ...btnProps }: CircularRectTextButtonProps, ref) => {
    return (
        <CircularRectTextButton
            ref={ref}
            className="ellipsisText"
            sx={{
                fontSize: "1.5rem",
                gap: "1rem",
                height: "3.8rem",
                padding: "0.5rem 1.5rem",
                background: themeColors.neutralWhite,
                color: themeColors.neutralBlack,
                "&:hover": {
                    background: themeColors.neutralBlack,
                    color: themeColors.neutralWhite,
                },
                ...sx,
            }}
            {...btnProps}>
            {children}
        </CircularRectTextButton>
    );
});
SlugPillFilled.displayName = "SlugPillFilled";

export const SlugPillBordered = ({ children, sx, ...btnProps }: CircularRectTextButtonProps) => {
    return (
        <CircularRectTextButton
            className="ellipsisText"
            sx={{
                fontSize: "1.5rem",
                gap: "1rem",
                height: "3.8rem",
                padding: "0.5rem 1.5rem",
                border: `1px solid ${themeColors.neutralBlack}`,
                background: "transparent",
                color: themeColors.neutralBlack,
                "&:hover": {
                    background: themeColors.neutralBlack,
                    color: themeColors.neutralWhite,
                },
                ...sx,
            }}
            {...btnProps}>
            {children}
        </CircularRectTextButton>
    );
};
